import * as React from "react";
import {FC, useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import VendorHeader from "../../components/VendorPage/VendorHeader/VendorHeader";
import {Dropdown, Tab, Tabs} from "react-bootstrap";
// @ts-ignore
import * as style from "./VendorPage.module.scss";
import VendorInfo from "../../components/VendorPage/VendorInfo/VendorInfo";
import VendorProducts from "../../components/VendorPage/VendorProducts/VendorProducts";
import VendorImages from "../../components/VendorPage/VendorImages/VendorImages";
import VendorAGBs from "../../components/VendorPage/VendorAGBs/VendorAGBs";
import VendorROWs from "../../components/VendorPage/VendorROWs/VendorROWs";
import {Client, handleApiError} from "../../services/ApiService";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import {
    faBalanceScaleRight,
    faBox,
    faFileSignature,
    faImages,
    faInfoCircle,
    faSearchPlus
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import {RouteComponentProps} from "react-router";
import VendorTransparenz from "../../components/VendorPage/VendorTransparenz/VendorTransparenz";

interface Props extends RouteComponentProps {
}


const VendorPage: FC<Props & any> = ({pageContext: {vendor}}) => {
    const [key, setKey] = useState('products');
    const [vendorData, setVendorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selection, setSelection] = useState('Produkte');
    const [data, setData] = useState(false);




    useEffect(() => {
        Client.Vendor.getVendorByURLSegment(vendor.URLSegment).then(res => {
            setVendorData(res.data[0]);
            setLoading(false);
            setData(true);
        }).catch(handleApiError);
    }, [])

    useEffect(() => {
        if(window.location.toString().includes("tab=agb")) {
            setKey("agb");
        }
    }, [])


    function getCurrentDisplay() {
        switch (selection) {
            case 'Info':
                return <VendorInfo vendor={vendorData}/>
                break;
            case 'Produkte':
                return <VendorProducts vendor={vendorData} loading={loading}/>
                break;
            case 'Bilder':
                return <VendorImages vendor={vendorData}/>
                break;
            case 'Transparenz':
                return <VendorTransparenz vendor={vendorData}/>
            case 'AGB':
                return <VendorAGBs vendor={vendorData}/>
            case 'Widerrufsrecht':
                return <VendorROWs vendor={vendorData}/>
            default:
                return <VendorProducts vendor={vendorData} loading={loading}/>
        }
    }

    if (data && vendorData.Status === "Disabled" || vendorData.Status === "DisabledByUser") {
        window.location.href = "/haendleruebersicht/";
    } else if(data) {
        return (
            <Layout pageTitle={vendor.CompanyName} description={vendor.Description} image={vendor.PreviewImage}>
                {console.log(vendorData)}
                <VendorHeader vendor={vendorData}/>
                {vendor.CompanyName ?
                    <>
                        <div className={style.customContainer + ' ' + style.navTabs + ' d-none d-lg-block'}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                            >
                                {/*// @ts-ignore*/}
                                <Tab
                                    eventKey="info"
                                    tabClassName={style.tab + ' ' + (key === 'info' ? style.active : '')}
                                    title={<> <FontAwesomeIcon icon={faInfoCircle}/> Info </>}>
                                    <VendorInfo vendor={vendorData} loading={loading}/>
                                </Tab>
                                {/*// @ts-ignore*/}
                                <Tab eventKey="products"
                                     tabClassName={style.tab + ' ' + (key === 'products' ? style.active : '')}
                                     title={<> <FontAwesomeIcon icon={faBox}/> Produkte </>}>
                                    <VendorProducts vendor={vendorData} loading={loading}/>
                                </Tab>
                                {/*// @ts-ignore*/}
                                <Tab eventKey="images"
                                     tabClassName={style.tab + ' ' + (key === 'images' ? style.active : '')}
                                     title={<> <FontAwesomeIcon icon={faImages}/> Bilder </>}>
                                    <VendorImages vendor={vendorData}/>
                                </Tab>
                                {/*// @ts-ignore*/}
                                {vendorData.Transparency ?
                                    <Tab eventKey="transparent"
                                         tabClassName={style.tab + ' ' + (key === 'transparent' ? style.active : '')}
                                         title={<><FontAwesomeIcon icon={faSearchPlus}/> Transparenz </>}>
                                        <VendorTransparenz vendor={vendorData}/>
                                    </Tab> : null
                                }
                                {/*// @ts-ignore*/}
                                {vendorData.AGB ?
                                    <Tab eventKey="agb" tabClassName={style.tab + ' ' + (key === 'agb' ? style.active : '')}
                                         title={<> <FontAwesomeIcon icon={faFileSignature}/> AGB </>}>
                                        <VendorAGBs vendor={vendorData}/>
                                    </Tab> : null
                                }
                                {/*// @ts-ignore*/}
                                {vendorData.ReturnPolicy ?
                                    <Tab eventKey="row" tabClassName={style.tab + ' ' + (key === 'row' ? style.active : '')}
                                         title={<> <FontAwesomeIcon icon={faBalanceScaleRight}/> Widerrufsrecht </>}>
                                        <VendorROWs vendor={vendorData}/>
                                    </Tab> : null
                                }
                            </Tabs>
                        </div>
                        <div className={style.customContainer + ' ' + style.navTabs + ' d-block d-lg-none'}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className={style.fakeTab}>
                                    {selection}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={style.fakeHolder}>
                                    <Dropdown.Item onClick={() => setSelection('Info')}>
                                        <FontAwesomeIcon icon={faInfoCircle} className={"me-2"} />
                                        Info
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setSelection('Produkte')}>
                                        <FontAwesomeIcon icon={faBox} className={"me-2"} />
                                        Produkte
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setSelection('Bilder')}>
                                        <FontAwesomeIcon icon={faImages} className={"me-2"} />
                                        Bilder
                                    </Dropdown.Item>
                                    {/*// @ts-ignore*/}
                                    {vendorData.Transparency ?
                                        <Dropdown.Item
                                            onClick={() => setSelection('Transparenz')}>
                                            <FontAwesomeIcon icon={faSearchPlus} className={"me-2"} />
                                            Transparenz
                                        </Dropdown.Item>
                                        : null
                                    }
                                    {/*// @ts-ignore*/}
                                    {vendorData.AGB ?
                                        <Dropdown.Item onClick={() => setSelection('AGB')}>
                                            <FontAwesomeIcon icon={faFileSignature} className={"me-2"} />
                                            AGB
                                        </Dropdown.Item>
                                        : null
                                    }
                                    {/*// @ts-ignore*/}
                                    {vendorData.ReturnPolicy ?
                                        <Dropdown.Item
                                            onClick={() => setSelection('Widerrufsrecht')}>
                                            <FontAwesomeIcon icon={faBalanceScaleRight} className={"me-2"} />
                                            Widerrufsrecht
                                        </Dropdown.Item>
                                        : null
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {getCurrentDisplay()}
                        </div>
                    </>
                    : <Loading type={"border"} message={"Daten werden geladen."}/>
                }
                {/*// @ts-ignore*/}
                <GoogleMap address={vendorData.Address}/>
            </Layout>
        )
    }

    return(
        <Layout pageTitle={vendor.CompanyName} description={vendor.Description} image={vendor.PreviewImage}>
            <Loading type={"border"} message={""}/>
        </Layout>
    )
}

export default VendorPage;
